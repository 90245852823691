<template>

    <div style="position : relative" class=" mb-2 ">
      <!-- Question Title, Info and Edit Actions -->
      <v-sheet
          class="wsRoundedLight overflow-hidden"
          style="border : 1px solid var(--wsBACKGROUND) !important"

      >
        <div @click="expand=!expand"
             class="py-3 d-flex justify-space-between pointer"
             :class="[{pointer : !singleQuestion}]"
             style="background-color: var(--wsLIGHTCARD)"
        >
          <!-- Question Title-->
          <div  class="d-flex align-center"  >

            <!-- Expand Icon-->
            <v-icon
                v-if="!singleQuestion"
                :class="expand ? 'rotate' : ''"
                class="pointer ml-2"
                :color="wsACCENT">
              mdi-menu-right
            </v-icon>

            <h5 class="wsDARKER" :class="[{'ml-4' : singleQuestion}]">
              {{ GET_QUESTION_TYPE_NAME(question.type) }} #     {{ index + 1 }}
            </h5>

          </div>

          <!-- Additional Info, Edit Button-->
          <div class="d-flex align-center ">
            <div  v-if="totalCorrectAnswers > 1">
              <div class="d-flex align-center justify-space-between" >

                <ft-select
                    @input="editQuestion"
                    v-model="question.multiple_type"
                    :items="multipleQuestionTypeSelect"
                >
                  <template #default="{text}">
                    <h5 class="wsACCENT text-no-wrap d-flex align-center">
                      <ws-tooltip :text="$t('testing.multiple_choice.description')">
                        <v-icon class="mr-1" :color="wsACCENT">mdi-information-outline</v-icon>
                      </ws-tooltip>

                      {{ text || $t('testing.multiple_choice.single') }}
                      <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                    </h5>
                  </template>
                </ft-select>

              </div>

            </div>
            <ft-select
                @input="editAction"
                :items="actionsSelect"
                @expand="editDropDownOn = $event"
                left left-icons >
              <ws-icon-button  class="mr-4"  />
            </ft-select>
          </div>
        </div>

        <v-expand-transition>
        <div v-if="expand || singleQuestion" style="border-top : 1px solid var(--wsBACKGROUND)">
          <div style="min-height: 60px">
            <!--  -->
            <ws-text-editor
                @input="editQuestion"
                v-model="question.content"
                :html="question.content_text"
                :placeholder="$t('EnterQuestionContent')"
                :question-id="question.uuid"
                no-file
            />
            <v-sheet height="40"></v-sheet>
          </div>


        </div>
        </v-expand-transition>


      </v-sheet>

      <!-- Answers-->
      <v-expand-transition>
        <div v-if="expand || singleQuestion">
          <question-answers
              :question="question"
              @update="handleAnswersUpdate"
          />

          <div v-if="!GET_HIDE_ADD_ANSWER(question.type)">
            <question-divider />
            <!-- Add Answer Button -->
            <v-sheet
                @click="addAnswer(question.type)"
                :color="wsBACKGROUND"
                class="wsRoundedLight pointer py-2 px-3"
                v-ripple
            >
              <h5 class="wsDARKER d-flex align-center">
                <v-icon :color="wsDARKER">mdi-plus</v-icon>
                {{ $t('AddAnswer') }}</h5>
            </v-sheet>
          </div>


        </div>
      </v-expand-transition>

      <ws-dialog
          v-model="displayDeleteDialog"
          :title="$t('DeleteQuestion')"
          close-delete
          display-confirm-delete
          @delete="deleteQuestion"
      />

    </div>

</template>

<script>
import questionAnswers from "@/components/pages/westudy/admin/course/questionBanks/questionAnswers.vue";
import questionDivider from "@/components/pages/westudy/admin/course/questionBanks/UI/questionDivider.vue";

import {mapActions, mapState} from "vuex";
import tests from "@/mixins/tests";
export default {
  name: "questionEditor",
  mixins : [tests],
  components : {
    questionAnswers,
    questionDivider
  },
  props : {
    question : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number,
      default : 0
    },
    singleQuestion : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      edit : false,
      editDropDownOn : false,
      expand : false,
      displayDeleteDialog : false
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
    multipleQuestionTypeSelect() {
      return [
        { text : this.$t('testing.multiple_choice.single') , value : null },
        { text : this.$t('testing.multiple_choice.all')  , value : 'all' },
        // { text : this.$t('testing.multiple_choice.percent')  , value : 'percent' },
      ]
    },
    totalAnswers() {
      return this.question.answers.length
    },
    totalCorrectAnswers() {
      if ( !this.question.answers ) {
        return []
      }
      return this.question.answers.filter(el=>el.correct).length
    },
    actionsSelect() {
      return [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
  },
  methods : {
    ...mapActions('tests', [
        'ADD_EDIT_BANK_ANSWER',
        'ADD_EDIT_BANK_QUESTION',
        'DELETE_QUESTION_ANSWER'
    ]),

    handleAnswersUpdate($event) {
      this.question = this.COPY($event);
      this.$emit('update', this.COPY($event))
    },
    editAction(type) {
      if ( type === 'delete' ) {
        this.displayDeleteDialog = true
      }
      if ( type === 'duplicate' ) {
        this.$emit('duplicate')
      }

    },
    async editQuestion() {

      let data = JSON.parse(JSON.stringify(this.question))
      data.lang = this.selectedLang

      let result = await this.ADD_EDIT_BANK_QUESTION(data)
      if ( !result ) {
        return
      }
    },
    deleteQuestion() {
      this.$emit('delete')
      this.displayDeleteDialog = false
    },

    async addAnswer(type = 'default') {

      let data = {
        question_bank_question_id : this.question.uuid,
        lang : this.selectedLang
      }

      let result = await this.ADD_EDIT_BANK_ANSWER(data)
      if (!result) {
        return
      }
      result.content = []

      if ( type === 'default') {
        this.question.answers.push(result)
      } else  if ( type === 'match') {
        this.question.answers.push(...result)
      }

      this.question = this.COPY(this.question)
      this.$emit('update' , this.COPY(this.question))
    },
    async deleteMatchAnswer(answer) {

      let result = await this.DELETE_QUESTION_ANSWER(answer.uuid)
      if ( !result )  {
        return
      }

      let index = this.question.answers.findIndex(el=>el.uuid === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)
      index = this.question.answers.findIndex(el=>el.match_parent === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)

    },
    deleteAnswer(index) {
      this.question.answers.splice(index,1)
    },

    expandAction() {
      this.notify('Expand')
    }

  },
  mounted() {
    if (this.question.expand) {
      this.expand = true
    }
  }
}
</script>

<style scoped>
.rotate {
  transform: rotate(90deg);
}
</style>