<template>
  <div>
    <v-hover #default="{hover}" v-for="(answer,i) in answers" :key="i">
      <div style="position:relative;">
        <v-sheet v-if="hover"
                 style="position:absolute; right: 40px; top : 16px; z-index: 1"
                 :color="wsWHITE"
        >
          <v-btn @click="$emit('delete', answer)"  :color="wsACCENT" icon>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-sheet>

        <question-divider />
        <div style="border : 1px solid var(--wsBACKGROUND)"
             class="wsRoundedLight d-flex  "
        >
          <div class="flex-grow-1" >
            <ws-text-editor
                @input="editAnswer(answer)"
                v-model="answer.content"
                :question-id="question.id"
                :placeholder="$t('EnterAnswerContent')"
                no-file
            />
            <v-sheet height="40"></v-sheet>
          </div>
          <div class="pt-2">
            <ws-check-box
                @input="editAnswer(answer)"
                v-model="answer.correct"
                :color="wsACCENT"
                :small="false"
            />
          </div>
        </div>

      </div>
    </v-hover>

  </div>
</template>

<script>
import questionDivider from "@/components/pages/westudy/admin/course/questionBanks/UI/questionDivider.vue";

export default {
  name: "defaultAnswers",
  components : {
    questionDivider
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    question : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      answers : []
    }
  },
  watch : {
    value : {
      handler()  {
        if (!this.EQ(this.value, this.answers)) {
          this.answers = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  methods : {
    editAnswer(answer) {
      this.$emit('input' , this.COPY(this.answers))
      this.$emit('edit-answer' , this.COPY(answer))
    }
  },
  mounted() {
    if ( this.value ) {
      this.answers = this.COPY(this.value)
    }
  }
}
</script>

<style scoped>

</style>