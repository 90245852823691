<template>
  <div class="pb-16 fill-height mx-auto" style="width: 100%; max-width: 760px" >

    <portal to="dash_course.header">
      <div>
        <ft-select
            @input="addQuestion($event)"
            :items="QUESTION_TYPES_SELECT"
        >
          <ws-button  :click-stop="false" label="AddQuestion"></ws-button>
        </ft-select>

      </div>
    </portal>

    <div v-if="!loading">
      <div class="pt-6 pb-16" />
      <questionEditor
          v-for="(question,i) in entity.questions"
          @update="handleQuestionUpdate"
          @delete="deleteQuestion(question.uuid, i)"
          @duplicate="duplicateQuestion(question.uuid)"
          :question="question"

          :index="i"
          :key="question.uuid + i"
      />



      <div v-if="!entity.questions || (entity.questions && !entity.questions.length)">

        <div class="d-flex justify-center">
          <v-icon :color="wsACCENT" size="80" class="mt-16">mdi-tooltip-question</v-icon>
        </div>

        <h2 class="wsDARKER mt-4 text-center">Створіть список запитань для тестів</h2>
        <h4 class="mt-2 font-weight-regular wsDARKER text-center">Почніть створювати колекцію запитань, які ви можете використовувати в різних тестах</h4>
      </div>

      <v-sheet height="100"></v-sheet>



    </div>
    <div v-else class="fill-height d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT"></v-progress-circular>
    </div>


  </div>
</template>

<script>
import {mapActions, mapMutations,  mapState} from "vuex";
import questionEditor from "@/components/pages/westudy/admin/course/questionBanks/questionEditor";
import tests from "@/mixins/tests";
export default {
  name: "questionBankEditor",
  mixins : [tests],
  components : {
    questionEditor
  },
  props : {
    uuid : {
      type : String,
      default : '',
    },
    bankId : {
      type : String,
      default : '',
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
  },
  data() {
    return {
      loading : true,
      entity : {
        questions : []
      },
      defaultQuestionType : 'default',
      defaultQuestionPoints : 1,
    }
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('tests', [
        'EDIT_QUESTION_BANK',
        'GET_QUESTION_BANK_EDIT',
        'ADD_EDIT_BANK_QUESTION',
        'DELETE_QUESTION',
        'COPY_QUESTION'
    ]),
    ...mapMutations('courses',['INIT_PAGE']),

    handleQuestionUpdate(question) {
      let index = this.entity.questions.findIndex(el => el.uuid === question.uuid)
      if (index === -1) {
        return
      }
      this.entity.questions[index] = this.COPY(question)
      this.entity = this.COPY(this.entity)
    },
    async addQuestion(type = 'default') {
      let data = {
        question_bank_id : this.bankId,
        type : type,
        points : this.defaultQuestionPoints
      }

      let result = await this.ADD_EDIT_BANK_QUESTION(data)
      if ( !result ) {
        return
      }
      this.entity.questions.push(result)
      result.expand = true
    },
    async deleteQuestion(uuid, index ) {
      let result = await this.DELETE_QUESTION(uuid)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }
      this.entity.questions.splice(index,1)
      this.notify(this.$t('TestQuestionDeleted'))
    },
    async duplicateQuestion( uuid  ) {
      let result = await this.COPY_QUESTION(uuid)
      if (!result ) {
        return
      }
      this.entity.questions.push(result)
      this.entity = Object.assign({} , this.entity)
    },
    async editBankName(name) {
      let data = JSON.parse(JSON.stringify(this.entity))
      data.lang = this.selectedLang
      data.name = name
      let result = await this.EDIT_QUESTION_BANK(data)
      if ( !result ) {
        return
      }
      this.notify(this.$t('ChangesSaved'))
    },

    async initPage() {
      this.loading = true
      let result = await this.GET_QUESTION_BANK_EDIT({uuid: this.bankId, lang: this.selectedLang})
      if (!result) {
        this.loading = false
        return
      }
      this.entity = result
      let name =  this.entity.name !== ' ' ? this.entity.name : this.$t('BlankQuestionBank')
      this.INIT_PAGE({
        icon : 'mdi-chevron-left',
        title : name,
        icon_route : this.businessDashLink('courses/' + this.uuid + '/question_banks'),
        width : 760,
        name_edit_action : this.editBankName
      })
      this.loading = false

    }

  },
  mounted() {
    if ( !this.uuid ) {
      return
    }
    this.initPage()
  }
}
</script>

<style scoped>

</style>