<template>
  <div v-if="answerComponent">
    <component
        :is="answerComponent"
        :question="question"
        v-model="question.answers"
        @input="editQuestion"
        @edit-answer="editAnswer"
        @delete="deleteAnswer"
    />
  </div>
  <div v-else>

  </div>

</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "questionAnswers",
  props : {
    question : {
      type : Object,
      default() {
        return {
          answers : []
        }
      }
    }
  },
  data() {
    return {
      answerComponent : null
    }
  },
  computed : {
    ...mapState('courses', ['selectedLang']),
  },
  methods : {
    ...mapActions('tests', [
      'ADD_EDIT_BANK_ANSWER',
      'DELETE_QUESTION_ANSWER'
    ]),

    deleteAnswer(answer) {

      if (this.question.type === 'match') {
        this.deleteMatchAnswer(answer)
        return
      }

      this.deleteRegularAnswer(answer)

    },
    async deleteRegularAnswer(answer) {
      let result = await this.DELETE_QUESTION_ANSWER(answer.uuid)
      if ( !result )  {
        return
      }
      let index = this.question.answers.findIndex(el=>el.uuid === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)
    },
    async deleteMatchAnswer(answer) {

      let result = await this.DELETE_QUESTION_ANSWER(answer.uuid)
      if ( !result )  {
        return
      }

      let index = this.question.answers.findIndex(el=>el.uuid === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)
      index = this.question.answers.findIndex(el=>el.match_parent === answer.uuid )
      if (index === -1) {
        return
      }
      this.question.answers.splice(index,1)
      this.editQuestion()

    },

    editAnswer(answer) {
      let data = this.COPY(answer)
      data.lang = this.selectedLang
      this.ADD_EDIT_BANK_ANSWER(data)
      this.$emit('update' , this.COPY(this.question))
    },

    editQuestion() {
      this.$emit('update' , this.COPY(this.question))
    }
  },
  beforeMount() {
    let answersComponents = {};
    const requiredPlugins = require.context('@/components/pages/westudy/admin/course/questionBanks/questionTypes/answers', false,  /Answers.vue$/);
    requiredPlugins.keys().forEach(filename => {
      const answerComponentName = filename
          .replace(/Answers.vue/g, '')
          .replace('./', '')
      answersComponents[answerComponentName] = requiredPlugins(filename).default || requiredPlugins(filename);
    });

    // Dynamically set the current component based on question.type
    if (this.question.type && answersComponents[this.question.type]) {
      this.answerComponent = answersComponents[this.question.type];
    }
  }
}
</script>

<style scoped>

</style>