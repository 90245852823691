<template>
  <div>

    <div v-for="(answer,i) in answers" :key="i">

      <question-divider />
      <div style="border : 1px solid var(--wsBACKGROUND)"
           class="wsRoundedLight d-flex  "
      >
        <div class="flex-grow-1" >
          <ws-text-field
              @change="editAnswer(answer)"
              v-model="answer.content_text"
              :placeholder="$t('EnterAnswerContent')"
              solo
              flat
              area
              rows="1"
              width="100%"
          />
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import questionDivider from "@/components/pages/westudy/admin/course/questionBanks/UI/questionDivider.vue";

export default {
  name: "inputAutoAnswers",
  components : {
    questionDivider
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    question : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      answers : []
    }
  },
  watch : {
    value : {
      handler()  {
        if (!this.EQ(this.value, this.answers)) {
          this.answers = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  methods : {
    editAnswer(answer) {
      this.$emit('input' , this.COPY(this.answers))
      this.$emit('edit-answer' , this.COPY(answer))
    }
  },
  mounted() {
    if ( this.value ) {
      this.answers = this.COPY(this.value)
    }
  }
}
</script>

<style scoped>

</style>