<template>
  <v-sheet :color="wsLIGHTCARD" height="8" class="d-flex justify-space-between" style="position: relative">
    <v-sheet style="border-top-right-radius: 50%;border-bottom-right-radius: 50%" color="wsWHITE" height="8" width="10"></v-sheet>
    <v-sheet style="border-top-left-radius: 50%;border-bottom-left-radius: 50%;" color="wsWHITE" height="8" width="10"></v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "questionDivider"
}
</script>

<style scoped>

</style>