<template>
  <div >
    <question-divider />
    <div style="border : 1px solid var(--wsBACKGROUND)" class="wsRoundedLight overflow-hidden">
      <v-hover #default="{hover}" v-for="row in answers.length/2" :key="row + 'row'">
        <div style="position: relative" class="d-flex "
             :class="[{borderBottom : row < answers.length/2 }]"


        >
          <v-sheet
              v-for="column in 2"
              :key="`column_${row}_${column}_${answers[((row-1)*2)].uuid}`"
              color="transparent"
              max-width="50%"
              width="50%"
              :class="[
                  {'pr-16' : column === 2 },
                    {borderRight : column === 1}
                    ]
              "
          >

            <ws-text-field
                :key="answers[((row-1)*2) + column - 1].uuid"
                @change="editAnswer(answers[((row-1)*2) + column - 1])"
                v-model="answers[((row-1)*2) + column - 1].content_text"
                :placeholder="$t('EnterText')"
                solo
                flat
                area
                rows="1"
                width="100%"
            />

            <!--          <input-->
            <!--              @change="editAnswer(question.answers[((row-1)*2) + column - 1])"-->
            <!--              v-model="question.answers[((row-1)*2) + column - 1].content_text"-->
            <!--              style="outline: none; width: 100%" type="text"-->
            <!--              :placeholder="$t('EnterText')"-->
            <!--          >-->

          </v-sheet>

          <v-sheet style="position:absolute; right : 0; top:4px; bottom: auto" min-width="40" color="transparent">
            <v-btn
                @click="$emit('delete', answers[((row-1)*2)])"
                v-if="hover" :color="wsACCENT" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-sheet>
        </div>

      </v-hover>
    </div>


  </div>
</template>

<script>
import questionDivider from "@/components/pages/westudy/admin/course/questionBanks/UI/questionDivider.vue";

export default {
  name: "defaultAnswers",
  components : {
    questionDivider
  },
  props : {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    question: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      answers: []
    }
  },
  watch: {
    value : {
      handler()  {
        if (!this.EQ(this.value, this.answers)) {
          this.answers = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  methods: {
    editAnswer(answer) {
      this.$emit('input', this.COPY(this.answers))
      this.$emit('edit-answer', this.COPY(answer))
    }
  },
  mounted() {
    if (this.value) {
      this.answers = this.COPY(this.value)
    }
  }
}
</script>

<style scoped>
.borderBottom {
  border-bottom: 1px solid var(--wsBACKGROUND);
}
.borderRight {
  border-right: 1px solid var(--wsBACKGROUND) !important;
}
</style>