export default {
    data() {
        return {
            HIDE_ADD_ANSWER_BUTTON_QUESTION_TYPES : [
                'inputAuto',
                'inputManual'
            ]
        }
    },
    computed : {
        QUESTION_TYPES_SELECT() {
            return [
                { text : this.$t('DefaultQuestion')    , value : 'default'  , icon : 'mdi-order-bool-descending-variant' },
                { text : this.$t('MatchQuestion')      , value : 'match'    , icon : 'mdi-format-columns' },
                { text : this.$t('testing.questions_types.inputAuto')    , value : 'inputAuto'   ,  icon : 'mdi-form-textbox'  },
                { text : this.$t('testing.questions_types.inputManual')  , value : 'inputManual' ,  icon : 'mdi-human-male-board'    },
                // { text : 'True/False Question' , value : 'true_false' }
            ]
        },
    },
    methods : {
        GET_QUESTION_PREVIEW_TEXT(content , length = 112) {
            content = content ?? []
            let textElement = content.find(el => el.isHtml)
            let text = this.$t('NoNameQuestion')
            if (textElement) {
                text = textElement.text || ''
                let parser = new DOMParser();
                let doc = parser.parseFromString(text, 'text/html');
                text = (doc.body.textContent || "").replace(/\n/g, ' ');
                if (text.length > length) {
                    text = text.substring(0, length) + '...';
                }

            }
            return text
        },
        GET_HIDE_ADD_ANSWER(type) {
           return this.HIDE_ADD_ANSWER_BUTTON_QUESTION_TYPES.includes(type)
        },
        GET_QUESTION_TYPE_NAME(type) {
            const questionType = this.QUESTION_TYPES_SELECT.find(el => el.value === type)
            return questionType ? questionType.text : type
        },
        QUESTION_HAS_REPLY(question , preview = false) {

            if ( preview ) {
                return this.QUESTION_HAS_CORRECT_REPLY(question)
            }

            if (question.type === 'default' ) {
                let hasAnswer = question.answers.find( el=> el.reply === true )
                return  !!hasAnswer
            }
            if (question.type === 'true_false' ) {
                return  question.question_reply_bool !== null
            }
            if (question.type === 'match' ) {
                let hasAnswer = question.answers.filter( el=> !!el.reply && !!el.matched_reply )
                return  hasAnswer.length === question.answers.length/2
            }

            if (question.type === 'inputAuto' ) {
                return !!question.question_reply_text
            }
            if (question.type === 'inputManual' ) {
                return !!question.question_reply_json && question.question_reply_json.length > 0
            }

            return false
        },
        QUESTION_HAS_CORRECT_REPLY(question) {

            if (question.type === 'default' && !question.multiple_type ) {
                let hasAnswer = question.answers.find( el=> el.reply === true )
                if ( !hasAnswer) {
                    return false
                }
                return  !!hasAnswer.correct
            } else if ( question.type === 'default' && question.multiple_type ) {
                let correctAnswersUuids = question.answers.filter( el=> el.correct === true ).map(el => el.uuid)
                let studentReplies = question.answers.filter( el=> el.reply === true ).map(el => el.uuid)
                if ( studentReplies.length !== correctAnswersUuids.length ) {
                    return false
                }

                return  studentReplies.filter(el => correctAnswersUuids.includes(el) ).length === correctAnswersUuids.length
            }
            else if (question.type === 'true_false' ) {
                return  question.question_reply_bool !== null
            } else if (question.type === 'match' ) {
                let replies = 0
                question.answers.forEach( answer => {
                    if ( answer.matched_reply && answer.correct) {
                        replies++
                    }
                })
                return replies === question.answers.length/2
            } else if (question.type === 'inputAuto' ) {
               return question.is_correct
            } else if (question.type === 'inputManual' ) {
                return question.question_reply_points > 0
            }

            return  false
        }
    }
}

